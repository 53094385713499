.App {
  text-align: center;
}


img {
 /* position: absolute; */
 width: 100%;
 height:100%;
 object-fit: none;
}

.image1 {
  width: 88px;
  height: 88px;;
  object-fit: contain;
  border-radius:60%;
  border: 1px solid black;
  /* padding: 10%; */
  background-color: #641cfc; 
}

.fruta {
  width: 38px;  
  height: 38px;
  object-fit: cover;
  margin-left: 44px;
  margin-right: 8px;
  /* border-radius: 10%; */
}
.logo {
  position: relative;
  display:flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin-top:27px;
  width:    100%;
  z-index:1;
}

svg{
  height:  38px;
  width:    108px;
  color: lightblue;
}

.twitter{
height: 38px;
width:    38px;
position: relative;
object-fit: contain;
padding-left:8px;

}
a{
  text-decoration: none;
  color:black;
  align-content:center;
  /* margin:auto; */
}
.sites{
  padding: 5px;
  border: 2px solid black;
  font-size: 33px;
  background: #d15eee;

  width:88%;
  display: flex; 
  flex-direction: column;
  text-align: center;
  margin: 18px;
  font-family:'Courier New', Courier, monospace;
  
}
.sites:hover{
  transform: scale(1.03);

}

div{
  display: flex;
  flex-direction:column;
  margin-top:11px;
  flex-wrap:wrap;
  width: 100vw;
  height:auto;
  align-content: center;
  justify-content: space-between;

}

@media  (max-height: 600px) {
  .logo {
    margin-top: 22px;
    position:relative;
  }
}

@media  (min-width: 800px) {
  .sites {
    width: 55%;

  }
}